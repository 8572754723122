
.no-data {
  color: #d1d4d7;
  text-transform: uppercase;
}

.data-row-edit :hover {
  background: #F8F8F8;
  cursor: pointer;
}

.data-row :hover {
  .row-Data:hover {
    background: #F8F8F8;
  }
}

.center-vertical {
  vertical-align: middle;
}

.cursor-text {
  cursor: text !important;
}

.summary-row {
  margin: 0 14px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  .summary-container {
    margin-top: 15px;
    display: inline-block;
    .summary-item {
      display: block;
      margin-bottom: 6px;
      span {
        font-weight: bold;
      }
    }
  }
}

.row-header {
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  &.col-header {
    border-bottom: 1px solid $gray-300;
    .col {
      border-right: 1px solid $gray-300;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
    .col:last-child {
      border-right: 0;
    }
  }
  .col {
    text-align: center;
    padding-top:4px;
    padding-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
}

.row-date {
  font-style: italic;
  font-weight: bold;
  color: $gray-700;
}

.accept:hover {
  color: green;
}

.deny:hover {
  color: red;
}

.delete:hover {
  color: black;
}

.spacer {
  padding: 5.5px 0;
}

.border {
  border-top: 1px solid $gray-700;
  padding: 10px;
}

.toggle-table {
  box-shadow: 0 0 3px 0px $gray-700;
  border-radius: 4px;
}

.mobile-table > .col {
  border-bottom: 1px solid $gray-500;
}
label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
}
