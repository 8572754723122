.break-day-of-week-container {
  width: 38px;
  height: 38px;
  border: 1px solid lightgrey;
  margin: 2px;
  padding-top: 7px;
  text-align: center;
  cursor: pointer;
}

.break-day-of-week-container:hover {
  background-color: #edf4ff;
}

.break-day-of-week-container.active {
  background-color: #3178C6;
  color: white;
}