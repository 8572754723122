.main {
  .navbar-light {
    display: none;
  }
}

@media screen and (max-width: 1172px) {
  .sidebar-fixed {
    .main {
      margin-left: 0;
    }
  }
  .sidebar {
    .sidebar-minimizer {
      display: none
    }
  }
  .main {
    .navbar-light {
      display: block;
    }
  }
  .mobileMenuClosed {
    margin-left: -250px;
  }

  .mobileMenuOpen {
    margin-left: 0;
  }
}

