
@media print {
  * {
    color: #000000 !important;

  }
  .sidebar {
    display: none;
  }
  .sidebar-fixed .main {
    margin: 0;
  }

  .wrapper {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 0;
  }

  .container-fluid {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .card {
    margin-bottom: 0 !important;
    border: none !important;
  }
  .cardBody {
    padding: 0 !important
  }
  .col-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 25% !important;
  }
  .download {
    display: none;
  }

  @page {
    margin: .75cm .05cm 0 .05cm;
  }
  .dont-print {
    display: none !important;
  }
  .print {
    width: 100% !important;
    //height: 100% !important;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .print-block {
    display: block !important;
  }

  .qr-badge {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .addPadding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .signature {
    margin-top: 0;
    margin-bottom: 0;
  }
  .page {
    break-page: avoid;
  }
  .allowMultiplePages {
    position: absolute;
  }
  .page-break { min-height: 1px; page-break-before: always; clear: both; }
}