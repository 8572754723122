.material-icons {
  &.md-10 {
    font-size: 10px;
  }
  &.md-12 {
    font-size: 12px;
  }
  &.md-14 {
    font-size: 14px;
  }
  &.md-16 {
    font-size: 16px;
  }
  &.md-18 {
    font-size: 18px;
  }
  &.md-20 {
    font-size: 20px;
  }
  &.md-22 {
    font-size: 22px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-26 {
    font-size: 26px;
  }
  &.md-28 {
    font-size: 28px;
  }
  &.md-30 {
    font-size: 30px;
  }
  &.md-32 {
    font-size: 32px;
  }
  &.md-34 {
    font-size: 34px;
  }
  &.md-36 {
    font-size: 36px;
  }
  &.md-38 {
    font-size: 38px;
  }
  &.md-40 {
    font-size: 40px;
  }
  &.md-42 {
    font-size: 42px;
  }
  &.md-44 {
    font-size: 44px;
  }
  &.md-46 {
    font-size: 46px;
  }
  &.md-48 {
    font-size: 48px;
  }
}