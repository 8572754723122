// scss-lint:disable NestingDepth, SelectorDepth
.sidebar {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: $sidebar-padding;
  color: $sidebar-color;
  background: $secondary;
  @include borders($sidebar-borders);

  li {
    list-style-type: none;
  }

  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: .8;

    &:hover {
      opacity: 1;
    }
  }

  .sidebar-header {
    flex: 0 0 $sidebar-header-height;
    text-align: center;
    background: $secondary !important;
    white-space: nowrap;
  }

  .user-info {
    transition: opacity 0ms linear;
  }

  .sidebar-form .form-control {
    color: $sidebar-form-color;
    background: $sidebar-form-bg;
    border: $sidebar-form-border;

    &::placeholder {
      color: $sidebar-form-placeholder-color;
    }
  }

  .sidebar-nav {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include sidebar-width($sidebar-borders, $sidebar-width);

    &::-webkit-scrollbar {
      position: absolute;
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-track {
      background-color: lighten($sidebar-bg, 5%);
      border-right: 1px solid darken($sidebar-bg, 5%);
      border-left: 1px solid darken($sidebar-bg, 5%);
    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($sidebar-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }
  }

  .nav {
    @include sidebar-width($sidebar-borders, $sidebar-width);
    flex-direction: column;
    min-height: 100%;
  }

  .nav-title {
    padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
    font-size: 11px;
    font-weight: 600;
    color: $sidebar-nav-title-color;
    text-transform: uppercase;
  }

  .nav-divider, .divider {
    height: 10px;
  }

  .nav-item {
    position: relative;
    margin: 0;
    transition: background .3s ease-in-out;
  }
  .trial-message  {
    position: relative;
    margin: 0;
    transition: background .3s ease-in-out;
  }

  .nav-dropdown-items {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: max-height .3s ease-in-out;

    .nav-item {
      padding: 0;
      list-style: none;
    }
  }

  .nav-link {
    display: block;
    white-space: nowrap;
    padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
    color: $sidebar-nav-link-color;
    text-decoration: none;
    background: $sidebar-nav-link-bg;
    @include borders($sidebar-nav-link-borders);
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }
    .nav-item-name {
      margin-left: 0;
      opacity: 1;
      transition: opacity .4s ease-in, margin-left .1s linear;
    }

    i {
      display: inline-block;
      margin: 0 ($sidebar-nav-link-padding-x / 2) 0 0;
      color: $sidebar-nav-link-icon-color;
      text-align: center;
    }

    .badge {
      float: right;
      margin-top: 2px;
    }

    &.active {
      color: $sidebar-nav-link-active-color;
      background: $secondary;
      border: 3px solid $primary;
      height: 45px;
      padding-left: 20.480px - 3px;
      padding-right: 20.480px - 3px;
      @include borders($sidebar-nav-link-active-borders);

      i {
        color: $white;
      }
    }

    &:hover {
      color: $sidebar-nav-link-hover-color;
      background: $secondary;
      border: 3px solid $primary;
      height: 45px;
      padding-left: 20.480px - 3px;
      padding-right: 20.480px - 3px;
      @include borders($sidebar-nav-link-hover-borders);

      i {
        color: $sidebar-nav-link-hover-icon-color;
      }
      &.nav-dropdown-toggle {
        padding-left: 20.480px - 3px;
      }

      &.nav-dropdown-toggle::before {
        background-image: $sidebar-nav-dropdown-indicator-hover;
        right: 17.48px !important;
      }
      .nav-item {
        padding: 0;
        list-style: none;
        .nav-link {
          padding-left: 32px;
        }
      }
    }

    @each $color, $value in $theme-colors {
      &.nav-link-#{$color} {
        background: $value;

        i {
          color: rgba(255, 255, 255, .7);
        }

        &:hover {
          background: darken($value, 5%) !important;

          i {
            color: #fff;
          }
        }
      }
    }
  }

  //  ex. <a class="nav-link nav-dropdown-toggle" href="#">Components</a>
  .nav-dropdown-toggle {
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      right: $sidebar-nav-link-padding-x;
      display: block;
      width: 8px;
      height: 8px;
      padding: 0;
      margin-top: -4px;
      content: "";
      background-image: $sidebar-nav-dropdown-indicator;
      background-repeat: no-repeat;
      background-position: center;
      transition: transform .3s;
    }
  }

  // ex.  <li class="nav-item nav-dropdown">
  .nav-dropdown.open {
    background: $secondary;
    @include borders($sidebar-nav-dropdown-borders);
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }
    > .nav-dropdown-items {
      max-height: 1500px;
      overflow: hidden;
    }

    > .nav-link.nav-dropdown-toggle::before {
      transform: rotate(-90deg);
    }
  }

  .nav-label {
    display: block;
    padding: ($sidebar-nav-link-padding-y / 8) $sidebar-nav-link-padding-x;
    color: $sidebar-nav-title-color;

    &:hover {
      color: $sidebar-color;
      text-decoration: none;
    }

    i {
      width: 20px;
      margin: -3px ($sidebar-nav-link-padding-x / 2) 0 0;
      font-size: 10px;
      color: $sidebar-nav-link-icon-color;
      text-align: center;
      vertical-align: middle;
    }
  }

  @if (lightness( $sidebar-bg ) < 40) {
    .progress {
      background-color: lighten($sidebar-bg, 15%) !important;
    }
  }

  .sidebar-footer {
    flex: 0 0 $sidebar-footer-height;
    padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;
    background: $secondary;
    @include borders($sidebar-footer-borders);
  }

  .sidebar-minimizer {
    position: relative;
    flex: 0 0 $sidebar-minimizer-height;
    background: $secondary;
    border: 0;
    @include borders($sidebar-minimizer-borders);

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      width: $sidebar-minimizer-height;
      height: $sidebar-minimizer-height;
      content: "";
      background-image: $sidebar-minimizer-indicator;
      background-repeat: no-repeat;
      background-position: center;
      background-size: $sidebar-minimizer-height / 4;
      transition: .3s;
    }

    &:focus,
    &.focus {
      outline: 0;
    }


      &::before {
        background-image: $sidebar-minimizer-hover-indicator;
      }
    }
  }


.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.collapsed {
  .main {
    margin-left: 55px !important;
  }
}

// Minimized Sidebar
.sidebar-minimized {
  .hidden-cn {
    display: none;
  }

  .sidebar {
    z-index: $zindex-sticky - 1;
    .sidebar-header {
      .justify-content-around {
        justify-content: center !important;
      }
    }
    .sidebar-nav {
      overflow: visible;
      @include sidebar-width($sidebar-borders, $sidebar-minimized-width);

      .nav-dropdown {
        .nav-dropdown-items {
          max-height: 0;
          overflow: hidden;
        }
      }
    }

    .nav {
      //@include sidebar-width($sidebar-borders, $sidebar-minimized-width);
      width: 50px;
      position: relative;
      padding-left: 0;
      margin: 0;
      white-space: nowrap;
      border-left: 0;
      .d-flex {
        display: block !important;
      }
    }

    .sidebar-minimizer::before {
      width: 100%;
      transform: rotate(-180deg);
    }

    .nav-item {
      overflow: hidden;
      border-left: 0 !important;

      &:hover {
        width: $sidebar-width + $sidebar-minimized-width;
        overflow: visible;

        > .nav-link {
          background: $sidebar-nav-link-hover-bg;

          i {
            color: $sidebar-nav-link-hover-color;
          }
        }
      }
    }

    .nav-link {
      position: relative;
      padding-left: 0;
      margin: 0;
      white-space: nowrap;
      border-left: 0 !important;
      //.nav-item-name {
      //  opacity: 0
      //}

      i {
        display: block;
        float: left;
        width: $sidebar-minimized-height;
        // padding: 0;
        // margin: 0 !important;
      }

      .badge {
        position: absolute;
        right: 15px;
        display: none;
      }

      &:hover {
        width: $sidebar-width + $sidebar-minimized-width;
        background: $sidebar-nav-link-hover-bg;

        .badge {
          display: inline;
        }
      }

      &.nav-dropdown-toggle::before {
        display: none;
      }
    }

    .nav-dropdown-items {
      .nav-item {
        width: 240px;
        overflow: hidden;

        .nav-link {
          width: $sidebar-width;
        }
      }
    }

    .nav > .nav-dropdown {
      > .nav-dropdown-items {
        display: none;
        max-height: 1000px;
        background: $sidebar-bg;
      }

      &:hover {
        background: $sidebar-nav-link-hover-bg;

        > .nav-dropdown-items {
          position: absolute;
          left: $sidebar-minimized-width;
          display: inline;
        }
      }
    }
  }
}

.sidebar-minimized {
  .sidebar {
    .nav-dropdown {
      .nav-dropdown-items {
        max-height: 0;
        transition: max-height 0s ease-out;
      }
    }
  }
}

.collapsed {
  .sidebar-container {
    .user-info {
      width: 0;
      opacity: 0;
      transition: opacity 0ms linear, width 0ms linear;
    }
    .nav-item {
      .nav-item-name {
        margin-left: -200px;
        opacity: 0;
        transition: opacity 0ms linear, margin-left 0ms linear;
      }
    }
    .trial-message {
      margin-left: -200px;
      opacity: 0;
      transition: opacity 0ms linear, margin-left 0ms linear;
    }
  }
}

.collapsed {
  .sidebar-container:hover {
    .user-info {
      width: auto;
      opacity: 1;
      transition: opacity .4s linear, width .4s linear;
    }
    .nav-item {
      .nav-item-name {
        margin-left: 0;
        opacity: 1;
        transition: opacity .4s ease-in, margin-left .1s linear;
      }
    }
  }
}
.nav-spacer {
  height: 100%;
  margin-top: -444px;
}
.trial-message {
  text-align: center;
  padding: 0.75rem 1.28rem;
  cursor: pointer;
}

