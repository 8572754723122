@import './variables';
@import './mixins';
@import './material-icon-custom.scss';
@import './data-tables-custom.scss';
@import './sidebar-menu.scss';
@import './_custom_forms.scss';
@import './_reports_custom.scss';
@import './_custom_print.scss';

// Here you can add other styles

body {
  background: $color-background;
}

.fb-select {
  width: 100%;
}

.active:invalid {
  box-shadow: 0 0 5px 1px red;
}

.invalid {
  box-shadow: 0 0 5px 1px red;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.errorDiv {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 50px;
}

.navbar {

  .form-inline {
    i {
      color: $navbar-color;
    }
    .form-control {
      min-width: 250px;
      color: $navbar-color;
      border: 0;

      &::placeholder {
        color: $navbar-color;
      }
    }
    .form-control::-webkit-input-placeholder {
      color: $navbar-color;
    }
    .form-control:-moz-placeholder {
      color: $navbar-color;
    }
    .form-control::-moz-placeholder {
      color: $navbar-color;
    }
    .form-control:-ms-input-placeholder {
      color: $navbar-color;
    }
  }
}

.form-control {
  height: calc(2.25rem + 2px);
}

.sidebar {
  height: calc(100vh) !important;
}

.sidebar {
  .sidebar-header {
    background: $color-sidebar-header;

    .img-avatar {
      width: 35px;
      border-radius: 50em;
      margin: 10px auto;
    }

    > .btn-group {
      margin-top: 10px;
    }

    .btn-link {
      color: $text-muted;

      &:hover {
        color: $sidebar-color;
        text-decoration: none;
      }
    }
  }

  span {
    display: inline-block;
    width: 20px;
    margin: 0 0.5rem 0 0;
    font-size: 14px;
    color: #9fa1a3;
    text-align: center;
  }
  a.active {
    font-weight: bold;
  }
  a.active span {
    color: #20a8d8;
  }
}

.s-alert-box {
  text-align: center;
}

.hidden {
  display: none;
}

.form-check {
  margin-left: 20px;
}
//
//.card-header .header-button {
//  padding: 0px;
//}

.module-header {
  background: $secondary;
  color: $white;
  height: 75px;
  line-height: 75px;
  vertical-align: middle;
  padding-left: 20px;
  font-size: 1.53125rem;
  font-weight: 500;
}

.header-button {
  display: inline-flex;
  margin-right: 15px;
}

.s-alert-box {
  z-index: 2000;
}

.clock-action-top {
  display: block;
  padding: 13px 14px 15px;
  margin: -13px -18px 20px;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.clock-action-bottom {
  display: block;
  padding: 13px 14px 15px;
  margin: 20px -18px -13px;
  text-align: left;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.timeholder {
  width: 100%;
  background: #fafafa;
  padding: 8px;
  margin: 0;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #e0e0e0;
}

.clockIn {
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box;
  padding: 10px;
  color: #fff;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.widget-body {
  margin-top: 10px;
}

.company-image {
  border: 1px solid #ddd;
}

.qr-badge {
  padding: 20px;
  width: 175px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: 20px;
  display: block;
}

.inline-button {
  padding: 10px 5px 0;
}

.inline-button:hover {
  cursor: pointer;
}

.table-header {
  font-weight: bold;
}

.row-badge {
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
}

.action-icon {
  cursor: pointer;
  color: dimgray;
  padding: 2px;
  margin: 0 5px
}

.clock-button {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 5px;
}

.without-ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.modal-break-container {
  background: #efefef;
  border-radius: 4px;
  background-clip: padding-box;
  padding: 10px;
  margin-bottom: 10px;

  legend {
    border-bottom: 1px dashed $gray-600;
    color: $gray-600;
    font-size: 16px;
  }
}

button {
  border-radius: 4px !important;
  background-clip: padding-box !important;;
}

.radio-button {
  cursor: pointer;
  color: #E0E0E0;
  padding: 16px;
  flex: 1px;
  margin-bottom: 0;
  margin-right: 8px;
}

.radio-button .checked {
  color: $blue;
  border-color: $blue;
}

.radio-button:disabled {
  cursor: not-allowed;
  color: #e1e1e1;
  border: 1px solid #e1e1e1;
}

.radio-button-icon {
  padding: 3px;
  background-color: #FFF;
  border: 2px solid $gray-600;
  border-radius: 50%;
  margin-right: 10px;
}

.radio-button-icon .checked {
  border-color: $blue;
  background: $blue;
  border: 2px solid $blue;
}

.radio-button-icon-inner {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.search-table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.searchBar {
  background: $white !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 45px;
  display: block;
  padding: 10px;
  color: $fb-gray !important;
}

.search-date-range {
  border: 1px solid $gray-800;
  max-width: 600px;
  margin: 10px;
}

.search-label {
  font-weight: bold;
}

.search-field {
  border: 1px solid $gray-400;
}

.modal-lg {
  max-width: 1100px;
}

.ReactCrop__crop-selection {
  border-radius: 50%;
}

.image-circle {
  border-radius: 50%;
}

.button-padding {
  padding: 6px 12px !important;
}

//.margin-top {
//  margin-top: .75rem;
//}

.clocked-in {
  color: seagreen;
}

.clocked-out {
  color: #960b0b;
}
.badge-success {
  background-color: #28a745 !important;
}

.no-padding-top {
  .card-body {
    padding-top: 0px;
  }
}

.custom-cc {
  padding: 25px 30px;
  border-radius: 10px;
  border: 1px solid;
  border-top: 2px solid $accent;
  width: 350px;
  height: 200px;
  .cc-number {
    span {
      font-size: 22px;
    }
  }
  .row {margin-left: 0; margin-right: 0}
  .cc-height {
    height: 40px;
  }
  span {
    font-size: 12px;
  }
  .font-small {
    font-size: 9px;
  }
  .align-text-right {
    text-align: right;
  }
}
.modal-md {
  max-width: 685px;
}
.margin-right-15 {
  margin-right: 15px
}
.card-header {
  font-weight: 600;
  font-size: large;
  background-color: white;
  border-bottom: none;
}
.card-accent-primary {
  border-top: solid 2px $accent;
}
.card-accent-primary-center {
  border-top: solid 2px $accent;
  text-align: center;
}
.no-button-margin {
  margin-top: 3px;
}
.header-text-link {
  font-size: small;
  font-weight: 400;
}

.map-container {
  position: initial !important;
  .map {
    height: 450px;
    width: 100%;
    position: initial !important;
  }
}
.geoLocationContainer {
  display: flex;
  align-items: center;
  width: 80px;
  margin: 0 auto;
}
.geoLocation, .geoLocation-hidden {font-size: 16px;}
.geoLocation-clock-in, .geoLocation-clock-out {cursor: pointer !important; color: grey; transition: color .15s}
.geoLocation-hidden {visibility: hidden !important; pointer-events: none !important;}
.geoLocation-clock-out:hover {color: #FF0000; opacity: .8;}
.geoLocation-clock-in:hover {color: #34844e; opacity: .8;}

.terminal-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  align-items: center;
}

.print {
  display: none;
}

.invalid :focus {
  box-shadow: none;
}

.invalid {
  box-shadow: 0 0 5px 1px red;
}

.ellipsis {
  flex: 1;
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
  }
}
.qr-badge {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.signature {
  margin-top: 45px;
  margin-bottom: 25px;
  .col {
    border-top: 1px solid $fbtime-dark-gray;
  }
}
.no-margin {
  margin: 0;
}
.alert {
  color: red;
}

.address {
  margin-left: 1em;
  font-size: smaller;
}

.print-block {display: none;}
//#codeList{
//  display: flex;
//  flex-direction: row;

//}

.layout-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.badge-lunch {
  color: #fff;
  background-color: #ff7900 !important;
}

.badge-break {
  color: #fff;
  background-color: #2271b6 !important;
}

// project schedule
.project-schedule-calendar .tui-full-calendar-dayname-container {
  background-color: #979797 !important;
}
.project-schedule-calendar .tui-full-calendar-dayname.tui-full-calendar-today {
  background-color: #F5A623; 
}
.project-schedule-calendar .tui-full-calendar-dayname-date-area {
  color: #ffffff !important;

}
.tui-full-calendar-dayname-container {
  height: 52px !important;
  text-align: center !important;
}
.tui-full-calendar-dayname-date-area p {  
  line-height: 26px;
  font-size: 14px;
  margin-bottom: 0;
}
.schedule-day-cell-head {
  text-align: center;
  font-size: 12px;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: initial;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #ddd !important;
}
.react-calendar-timeline .rct-sidebar {
  border-right: none !important;
}
.rct-vertical-lines .rct-vl.week-layout {
  background: -webkit-gradient(linear,
    left top, right top,
    color-stop(25%, transparent),
    color-stop(25%, #ddd), 
    color-stop(25.5%, transparent), 
    color-stop(49.5%, #ddd),
    color-stop(50%, transparent),
    color-stop(74.5%, #ddd),
    color-stop(75%, transparent)
    ) !important;
    background: -o-linear-gradient(left,
    transparent 25%,
    #ddd 25% 25.5%, 
    transparent 25.5% 49.5%, 
    #ddd 49.5% 50%,
    transparent 50% 74.5%,
    #ddd 74.5% 75%,
    transparent 75%
    ) !important;
    background: linear-gradient(to right,
    transparent 25%,
    #ddd 25% 25.5%, 
    transparent 25.5% 49.5%, 
    #ddd 49.5% 50%,
    transparent 50% 74.5%,
    #ddd 74.5% 75%,
    transparent 75%
    ) !important;  
}
.rct-vertical-lines .rct-vl.non-working-day,
.timeline-legend.non-working-day {
  background: #f0f0f0 !important;
}
.rct-vertical-lines .rct-vl.holiday,
.timeline-legend.holiday {
  background: #cdcdcd !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background-color: #fff;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: #fff;
}

.schedule-header-date {
  background: #979797;
  color: #fff;
}
.schedule-header-date.today {
  background: #F5A624;
}
.schedule-header-date p {
  margin: 0;
}
.fc-button {
  background: white !important;
  color: black !important;
  border-color: #DFDFDF !important;
}
.fc-button.fc-button-active {
  background: #F5A624 !important;
  color: #fff !important;
}
.fc-bgevent {
  background: #cdcdcd;
  opacity: 0.7;
}
.fc-nonbusiness {
  background: #d7d7d7;
  opacity: 0.3;
}
.day-problem-header {
  color: rgb(173, 80, 80);
  font-weight: bolder;
}

.fc-time-grid-container {
  overflow: hidden hidden !important;
}

.data-row-issue {
  background-color: #E74C3C;
  color: white;

  .action-icon, .geoLocation-clock-in, .geoLocation-clock-out {
    color: white;
  }
}

.data-row-issue :hover {
  background-color: #db6053;
}