.not-bold{
    font-weight: normal;
}

.flex-container {
    display: flex;
    justify-content: flex-start;
    position: relative;
  }

.item-center {
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.item-left {
    flex: 0 1 auto;
    margin-right: auto;
}

.schedule-title-period{
    margin-right: auto;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

// .btn-group>.btn-group:first-child>.btn, .btn-group>.btn:first-child {
//     padding-right: 30px;
// }

// .btn-group>.btn-group:last-child>.btn, .btn-group>.btn:last-child {
//     padding-left: 30px;
// }

.btn-outline-active{
    color: #FFF;
    background-color: #ff7900;
    border-color: #ff7900;
    border-top-color: rgb(246, 153, 68);
    border-right-color: rgb(246, 153, 68);
    border-bottom-color: rgb(246, 153, 68);
    border-left-color: rgb(246, 153, 68);
}

.btn-outline-inactive{
    color: #abaeaf;
    border-color: #e5e5e5;
    border-top-color: rgb(229, 229, 229);
    border-right-color: rgb(229, 229, 229);
    border-bottom-color: rgb(229, 229, 229);
    border-left-color: rgb(229, 229, 229);
    background-color: #FFF;
}

.tui-full-calendar-dayname-date-area{
    cursor: pointer;
}

.schedule-header {
    background-color:#c4c4c4;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
}

.calendar-light-background{
    background-color: #f7fafc;    
}

.calendar-body{
    padding: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

.employee-section{
    min-height: 100%;
    background-color: #FFF;
}

.employee-section-header{
    font-weight: bolder;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 15px;
}

.navigate-header{
    padding-top: 15px;    
}

.navigate-buttons{
    padding-top: 4px;
    padding-bottom: 0;
}

.month-year-header{
    padding-top: 5px;
}

.drop-shadow{
    box-shadow: 2px 2px 2px #edf0f2;
}