// Bootstrap overrides

//
// Color system
//

$fbtime-dark-gray: #303030 !default;
$fbtime-light-gray: #F7f8fc !default;
$fbtime-blue: #2271b6 !default;
$fbtime-orange: #ff7900 !default;

$white:                           #fff;
$gray-100:                        #f8f9fa;
$gray-200:                        #e4e5e6;
$gray-300:                        #d1d4d7;
$gray-400:                        #869fac;
$gray-500:                        #678898;
$gray-600:                        #9fa1a3;
$gray-700:                        #3e515b;
$gray-800:                        #2a2c36;
$gray-900:                        #151b1e;
$black:                           #000 !default;
$fb-gray:                         #303030 !default;
$fb-light-gray:                   #F7f8f !default;

$blue:                            #2271b6 !default;
$indigo:                          #6610f2 !default;
$purple:                          #6f42c1 !default;
$pink:                            #e83e8c !default;
$red:                             #f86c6b;
$orange:                          #ff7900 !default;
$yellow:                          #ffc107 !default;
$green:                           #43a863;
$teal:                            #20c997 !default;
$cyan:                            #63c2de;

$colors: (
  "blue":                           $blue,
  "indigo":                         $indigo,
  "purple":                         $purple,
  "pink":                           $pink,
  "red":                            $red,
  "orange":                         $orange,
  "yellow":                         $yellow,
  "green":                          $green,
  "teal":                           $teal,
  "cyan":                           $cyan,
  "white":                          $white,
  "gray":                           $gray-600,
  "gray-dark":                      $gray-800
);

$primary: $fbtime-dark-gray;
$secondary: $fb-gray;
$accent: $fbtime-orange;
$text: $fbtime-light-gray;


$theme-colors: (
  "primary":                        $fbtime-dark-gray,
  "secondary":                      $fb-gray,
  "accent":                         $orange,
  "success":                        $green,
  "info":                           $fbtime-blue,
  "warning":                        $orange,
  "danger":                         $red,
  "light":                          $gray-100,
  "dark":                           $gray-800,

  "blue":                           $blue,
  "indigo":                         $indigo,
  "purple":                         $purple,
  "pink":                           $pink,
  "red":                            $red,
  "orange":                         $orange,
  "yellow":                         $yellow,
  "green":                          $green,
  "teal":                           $teal,
  "cyan":                           $cyan,

  "gray-100":                       $gray-100,
  "gray-200":                       $gray-200,
  "gray-300":                       $gray-300,
  "gray-400":                       $gray-400,
  "gray-500":                       $gray-500,
  "gray-600":                       $gray-600,
  "gray-700":                       $gray-700,
  "gray-800":                       $gray-800,
  "gray-900":                       $gray-900
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  false;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  0.875rem;

// Breadcrumbs

$breadcrumb-padding-x:            2rem !default;
$breadcrumb-bg:                   transparent;
$breadcrumb-margin-bottom:        0;

// Cards

$card-border-color:               $gray-300;
$card-cap-bg:                     $gray-100;

// Dropdowns

$dropdown-padding-y:              0;
$dropdown-border-color:           $gray-200;
$dropdown-divider-bg:             $gray-100;

// Buttons

$btn-secondary-border:            $gray-300;

// Progress bars

$progress-bg:                     $gray-100;

// Tables

$table-bg-accent:                 $gray-100;
$table-bg-hover:                  $gray-100;

// Forms

$input-group-addon-bg:            $gray-100;
$input-border-color:              $gray-200;
$input-group-addon-border-color:  $gray-200;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1300px,
        xl: 1400px
);

